var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scrollable scrollable-dialog wrapper rounded flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-center align-md-start justify-start pa-5 pb-1"},[_c('div',{staticClass:"mr-md-5 mb-1 d-flex align-start"},[_c('v-text-field',{staticClass:"flex-grow-0",attrs:{"label":"Search","hint":"Search by questionnaire title","persistent-hint":"","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","clearable":""},on:{"input":function($event){_vm.page = 1}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0"},[_c('ShowPerPage',{staticClass:"ml-auto",attrs:{"labelPerPage":"Questionnaires","currentPerPage":_vm.pageSize,"options":_vm.showPerPageOptions}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":!_vm.historyLoader ? _vm.paginatedHistory : [],"loading":_vm.historyLoader,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.green",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"bullet small",class:{ filgreen: header.value == 'green' }})]}},{key:"header.yellow",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"bullet small",class:{ filyellow: header.value == 'yellow' }})]}},{key:"header.red",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"bullet small",class:{ filred: header.value == 'red' }})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.questionnaireTypeId == 'psycho')?_c('span',{staticClass:"primary rounded-pill px-3 py-1 white--text"},[_vm._v(" "+_vm._s(item.questionnaireTypeName)+" ")]):_vm._e(),(item.questionnaireTypeId == 'ability')?_c('span',{staticClass:"secondary rounded-pill px-3 py-1 white--text"},[_vm._v(" "+_vm._s(item.questionnaireTypeName)+" ")]):_vm._e(),(item.questionnaireTypeId == 'elearn')?_c('span',{staticClass:"accent rounded-pill px-3 py-1 white--text"},[_vm._v(" "+_vm._s(item.questionnaireTypeName)+" ")]):_vm._e(),(item.questionnaireTypeId == 'form')?_c('span',{staticClass:"error rounded-pill px-3 py-1 white--text"},[_vm._v(" "+_vm._s(item.questionnaireTypeName)+" ")]):_vm._e()]}},{key:"item.emailSentOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailSentOn ? _vm.dayjs(item.emailSentOn).format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.assignmentEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateEnd ? _vm.dayjs(item.dateEnd).format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title.length > 70 ? item.title.substring(0, 60) + "..." : item.title)+" ")]}},{key:"item.importance",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary px-2 pa-1 rounded-pill white--text",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.importance)+" ")])]}},{key:"item.send",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex white--text"},[_c('div',{staticClass:"bullet filgreen disabled mr-2"},[_vm._v(" "+_vm._s(item.sentToNum)+" ")]),_c('div',{staticClass:"bullet filgreen"},[_vm._v(" "+_vm._s(item.openedByNum)+" ")])])]}},{key:"item.isFinalized",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"bullet small",class:{
            success: item.isFinalized,
            error: !item.isFinalized,
          }})])]}},{key:"item.dateCompleted",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCompleted ? _vm.dayjs(item.dateCompleted).format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.executionTimeMinSec",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.executionTimeMinSec && item.executionTimeMinSec != "0" ? item.executionTimeMinSec : "-")+" ")]}},{key:"item.green",fn:function(ref){
          var item = ref.item;
return [(
          item.questionnaireTypeId == 'psycho' &&
            item.isFinalized &&
            _vm.info.currentLogin.gmCanReadResults
        )?_c('div',{staticClass:"bullet large filgreen"},[_vm._v(" "+_vm._s(item.green)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.yellow",fn:function(ref){
        var item = ref.item;
return [(
          item.questionnaireTypeId == 'psycho' &&
            item.isFinalized &&
            _vm.info.currentLogin.gmCanReadResults
        )?_c('div',{staticClass:"bullet large filyellow"},[_vm._v(" "+_vm._s(item.yellow)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.red",fn:function(ref){
        var item = ref.item;
return [(
          item.questionnaireTypeId == 'psycho' &&
            item.isFinalized &&
            _vm.info.currentLogin.gmCanReadResults
        )?_c('div',{staticClass:"bullet large filred"},[_vm._v(" "+_vm._s(item.red)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(
          item.isFinalized &&
            ((item.questionnaireTypeId == 'psycho' &&
              _vm.info.currentLogin.gmCanReadResults) ||
              (item.questionnaireTypeId == 'elearn' &&
                _vm.info.currentLogin.gmCanAccessElearning) ||
              (item.questionnaireTypeId == 'form' &&
                _vm.info.currentLogin.gmCanAccessForms))
        )?_c('v-btn',{attrs:{"small":"","width":"60px"},on:{"click":function($event){return _vm.onShowReport(item)}}},[_vm._v("report")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper py-3 mt-auto"},[(_vm.pages > 0)?_c('v-pagination',{attrs:{"length":_vm.pages},on:{"input":function($event){_vm.tab = null}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }