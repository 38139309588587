<template>
  <div>
    <div class="d-flex flex-column mb-4">
      <div class="d-flex align-center mb-2">
        <div class="body-2 font-weight-medium mr-2">Identity:</div>

        <div class="body-2">{{ user.identityNumber }}</div>
      </div>

      <div class="d-flex align-center mb-2">
        <div class="body-2 font-weight-medium mr-2">Email:</div>

        <div class="body-2">{{ user.userEmail }}</div>
      </div>

      <div class="d-flex align-center mb-2">
        <div class="body-2 font-weight-medium mr-2">Assignment Sent:</div>

        <div class="body-2">
          {{
            assignmentOfUser.dateToSend
              ? dayjs(assignmentOfUser.dateToSend).format("DD/MM/YYYY")
              : "-"
          }}
        </div>
      </div>

      <div class="d-flex align-center">
        <div class="body-2 font-weight-medium mr-2">Assignment End:</div>

        <div class="body-2">
          {{
            assignmentOfUser.dateToSend
              ? dayjs(assignmentOfUser.dateToEnd).format("DD/MM/YYYY")
              : "-"
          }}
        </div>
      </div>

      <v-btn
        class="align-self-start mt-2 white--text"
        small
        depressed
        v-if="currentAssignment && currentAssignment.sendTypeId == 'newtab'"
        :href="
          `${window.location.origin}/assignee/${assignmentOfUser.questionnaires[0].assignUserQuesId}`
        "
        target="_blank"
        color="filyellow"
        >Open Assignment</v-btn
      >
    </div>

    <div class="d-flex align-center">
      <div class="subtitle-1 font-weight-medium mr-4">
        Questionnaires ({{ assignmentOfUser.questionnaires.length }})
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="assignmentOfUser.questionnaires"
      hide-default-footer
      class="mb-3"
      :items-per-page="-1"
    >
      <!-- HEADERS -->
      <template v-slot:[`header.green`]="{ header }">
        <div
          class="bullet small"
          :class="{ filgreen: header.value == 'green' }"
        ></div>
      </template>

      <template v-slot:[`header.yellow`]="{ header }">
        <div
          class="bullet small"
          :class="{ filyellow: header.value == 'yellow' }"
        ></div>
      </template>

      <template v-slot:[`header.red`]="{ header }">
        <div
          class="bullet small"
          :class="{ filred: header.value == 'red' }"
        ></div>
      </template>

      <!-- ITEMS -->
      <template v-slot:[`item.questionnaireName`]="{ item }">
        {{
          item.questionnaireShortName
            ? item.questionnaireShortName
            : item.questionnaireName
        }}
      </template>

      <template v-slot:[`item.isFinalized`]="{ item }">
        <div class="d-flex align-center justify-center">
          <div
            class="bullet small"
            :class="{
              success: item.isFinalized,
              error: !item.isFinalized,
            }"
          ></div>
        </div>
      </template>

      <template v-slot:[`item.dateCompleted`]="{ item }">
        {{
          item.dateCompleted
            ? dayjs(item.dateCompleted).format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.executionTimeMinSec`]="{ item }">
        {{
          item.executionTimeMinSec && item.executionTimeMinSec != "0"
            ? item.executionTimeMinSec
            : "-"
        }}
      </template>

      <template v-slot:[`item.green`]="{ item }">
        <div
          class="bullet large filgreen"
          v-if="
            item.questionnaireTypeId == 'psycho' &&
              item.isFinalized &&
              info.currentLogin.gmCanReadResults
          "
        >
          {{ item.green }}
        </div>

        <div v-else>-</div>
      </template>

      <template v-slot:[`item.yellow`]="{ item }">
        <div
          class="bullet large filyellow"
          v-if="
            item.questionnaireTypeId == 'psycho' &&
              item.isFinalized &&
              info.currentLogin.gmCanReadResults
          "
        >
          {{ item.yellow }}
        </div>

        <div v-else>-</div>
      </template>

      <template v-slot:[`item.red`]="{ item }">
        <div
          class="bullet large filred"
          v-if="
            item.questionnaireTypeId == 'psycho' &&
              item.isFinalized &&
              info.currentLogin.gmCanReadResults
          "
        >
          {{ item.red }}
        </div>

        <div v-else>-</div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="
            item.isFinalized &&
              ((item.questionnaireTypeId == 'psycho' &&
                info.currentLogin.gmCanReadResults) ||
                (item.questionnaireTypeId == 'elearn' &&
                  info.currentLogin.gmCanAccessElearning) ||
                (item.questionnaireTypeId == 'form' &&
                  info.currentLogin.gmCanAccessForms))
          "
          small
          @click="onShowReport(item, panel)"
          width="60px"
          >report</v-btn
        >

        <div v-else>
          <v-btn
            v-if="role == 'filistos'"
            small
            depressed
            color="error"
            @click="onDelete(item)"
            width="60px"
            :loading="loader"
            >delete</v-btn
          >

          <div v-else class="mock">-</div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  props: ["user", "panel"],
  data() {
    return {
      headers: [
        { text: "Questionnaire Type", value: "questionnaireTypeName" },
        { text: "Questionnaire Title", value: "questionnaireName" },
        { text: "Completed", value: "isFinalized" },
        { text: "Completion Date", value: "dateCompleted" },
        { text: "Execution Time", value: "executionTimeMinSec" },
        { text: "Green", value: "green" },
        { text: "Yellow", value: "yellow" },
        { text: "Red", value: "red" },
        { text: "", value: "action" },
      ],
      window: window,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      getAssignmentOfUserLoader: (state) =>
        state.assignments.getAssignmentOfUserLoader,
      role: (state) => state.auth.role,
      assignmentOfUser: (state) => state.assignments.assignmentOfUser,
      currentAssignment: (state) => state.assignments.currentAssignment,
      info: (state) => state.base.info,
    }),
  },

  methods: {
    ...mapMutations(["toggleShowReport", "toggleSnackbar"]),
    ...mapActions(["getAssignmentOfUser"]),
    onShowReport(item, panel) {
      if (this.$route.name == "editUserUserAssignments") {
        this.$router.push(
          `/superadmin/clients/${this.$route.params.clientId}/users/${this.$route.params.userId}/report/${item.assignUserQuesId}`
        );
      } else {
        this.toggleShowReport({
          open: true,
          id: item.assignUserQuesId,
          index: panel,
        });
      }
    },
    async onDelete(item) {
      this.loader = true;

      try {
        await axios.get(
          `${process.env.VUE_APP_BASE_URL}/superadmin/DeleteAssignmentUserQuestionnaire/${item.assignUserQuesId}`
        );

        await this.getAssignmentOfUser({
          assignmentId: this.assignmentOfUser.assignmentId,
          userId: this.user.clientUserId,
        });

        this.toggleSnackbar({
          open: true,
          color: "success",
          text: "Questionnaire has been delete from assignment",
          timeout: 5000,
        });
      } catch (e) {
        console.log(e);

        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: 5000,
        });
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  &.small {
    height: 12px;
    width: 12px;
  }
}

.mock {
  width: 60px;
}
</style>
