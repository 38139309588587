var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column mb-4"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"body-2 font-weight-medium mr-2"},[_vm._v("Identity:")]),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.user.identityNumber))])]),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"body-2 font-weight-medium mr-2"},[_vm._v("Email:")]),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.user.userEmail))])]),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('div',{staticClass:"body-2 font-weight-medium mr-2"},[_vm._v("Assignment Sent:")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.assignmentOfUser.dateToSend ? _vm.dayjs(_vm.assignmentOfUser.dateToSend).format("DD/MM/YYYY") : "-")+" ")])]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"body-2 font-weight-medium mr-2"},[_vm._v("Assignment End:")]),_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.assignmentOfUser.dateToSend ? _vm.dayjs(_vm.assignmentOfUser.dateToEnd).format("DD/MM/YYYY") : "-")+" ")])]),(_vm.currentAssignment && _vm.currentAssignment.sendTypeId == 'newtab')?_c('v-btn',{staticClass:"align-self-start mt-2 white--text",attrs:{"small":"","depressed":"","href":((_vm.window.location.origin) + "/assignee/" + (_vm.assignmentOfUser.questionnaires[0].assignUserQuesId)),"target":"_blank","color":"filyellow"}},[_vm._v("Open Assignment")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-1 font-weight-medium mr-4"},[_vm._v(" Questionnaires ("+_vm._s(_vm.assignmentOfUser.questionnaires.length)+") ")])]),_c('v-data-table',{staticClass:"mb-3",attrs:{"headers":_vm.headers,"items":_vm.assignmentOfUser.questionnaires,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header.green",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"bullet small",class:{ filgreen: header.value == 'green' }})]}},{key:"header.yellow",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"bullet small",class:{ filyellow: header.value == 'yellow' }})]}},{key:"header.red",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"bullet small",class:{ filred: header.value == 'red' }})]}},{key:"item.questionnaireName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.questionnaireShortName ? item.questionnaireShortName : item.questionnaireName)+" ")]}},{key:"item.isFinalized",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"bullet small",class:{
            success: item.isFinalized,
            error: !item.isFinalized,
          }})])]}},{key:"item.dateCompleted",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCompleted ? _vm.dayjs(item.dateCompleted).format("DD/MM/YYYY") : "-")+" ")]}},{key:"item.executionTimeMinSec",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.executionTimeMinSec && item.executionTimeMinSec != "0" ? item.executionTimeMinSec : "-")+" ")]}},{key:"item.green",fn:function(ref){
          var item = ref.item;
return [(
          item.questionnaireTypeId == 'psycho' &&
            item.isFinalized &&
            _vm.info.currentLogin.gmCanReadResults
        )?_c('div',{staticClass:"bullet large filgreen"},[_vm._v(" "+_vm._s(item.green)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.yellow",fn:function(ref){
        var item = ref.item;
return [(
          item.questionnaireTypeId == 'psycho' &&
            item.isFinalized &&
            _vm.info.currentLogin.gmCanReadResults
        )?_c('div',{staticClass:"bullet large filyellow"},[_vm._v(" "+_vm._s(item.yellow)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.red",fn:function(ref){
        var item = ref.item;
return [(
          item.questionnaireTypeId == 'psycho' &&
            item.isFinalized &&
            _vm.info.currentLogin.gmCanReadResults
        )?_c('div',{staticClass:"bullet large filred"},[_vm._v(" "+_vm._s(item.red)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(
          item.isFinalized &&
            ((item.questionnaireTypeId == 'psycho' &&
              _vm.info.currentLogin.gmCanReadResults) ||
              (item.questionnaireTypeId == 'elearn' &&
                _vm.info.currentLogin.gmCanAccessElearning) ||
              (item.questionnaireTypeId == 'form' &&
                _vm.info.currentLogin.gmCanAccessForms))
        )?_c('v-btn',{attrs:{"small":"","width":"60px"},on:{"click":function($event){return _vm.onShowReport(item, _vm.panel)}}},[_vm._v("report")]):_c('div',[(_vm.role == 'filistos')?_c('v-btn',{attrs:{"small":"","depressed":"","color":"error","width":"60px","loading":_vm.loader},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("delete")]):_c('div',{staticClass:"mock"},[_vm._v("-")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }