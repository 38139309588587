<template>
  <div
    class="scrollable scrollable-dialog wrapper rounded flex-grow-1 d-flex flex-column"
  >
    <div
      class="d-flex flex-column flex-md-row align-center align-md-start justify-start pa-5 pb-1"
    >
      <div class="mr-md-5 mb-1 d-flex align-start">
        <v-text-field
          label="Search"
          hint="Search by questionnaire title"
          persistent-hint
          outlined
          class="flex-grow-0"
          dense
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="search"
          @input="page = 1"
        ></v-text-field>
      </div>

      <div class="d-flex flex-column flex-sm-row ml-md-auto mb-2 mb-md-0">
        <ShowPerPage
          class="ml-auto"
          labelPerPage="Questionnaires"
          :currentPerPage="pageSize"
          :options="showPerPageOptions"
        />

        <!-- <v-btn class="mr-sm-4 mb-4 mb-sm-0" depressed>
              <v-icon left>mdi-cloud-download</v-icon>
              CSV
            </v-btn>
    
            <v-btn depressed>
              <v-icon left>mdi-cloud-download</v-icon>
              PDF
            </v-btn> -->
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="!historyLoader ? paginatedHistory : []"
      :loading="historyLoader"
      item-key="id"
      hide-default-footer
    >
      <!-- HEADERS -->
      <template v-slot:[`header.green`]="{ header }">
        <div
          class="bullet small"
          :class="{ filgreen: header.value == 'green' }"
        ></div>
      </template>

      <template v-slot:[`header.yellow`]="{ header }">
        <div
          class="bullet small"
          :class="{ filyellow: header.value == 'yellow' }"
        ></div>
      </template>

      <template v-slot:[`header.red`]="{ header }">
        <div
          class="bullet small"
          :class="{ filred: header.value == 'red' }"
        ></div>
      </template>

      <!-- ITEMS -->
      <template v-slot:[`item.type`]="{ item }">
        <span
          v-if="item.questionnaireTypeId == 'psycho'"
          class="primary rounded-pill px-3 py-1 white--text"
        >
          {{ item.questionnaireTypeName }}
        </span>

        <span
          v-if="item.questionnaireTypeId == 'ability'"
          class="secondary rounded-pill px-3 py-1 white--text"
        >
          {{ item.questionnaireTypeName }}
        </span>

        <span
          v-if="item.questionnaireTypeId == 'elearn'"
          class="accent rounded-pill px-3 py-1 white--text"
        >
          {{ item.questionnaireTypeName }}
        </span>

        <span
          v-if="item.questionnaireTypeId == 'form'"
          class="error rounded-pill px-3 py-1 white--text"
        >
          {{ item.questionnaireTypeName }}
        </span>
        <!-- (psycho, ability, elearning, form) -->
      </template>

      <template v-slot:[`item.emailSentOn`]="{ item }">
        {{
          item.emailSentOn ? dayjs(item.emailSentOn).format("DD/MM/YYYY") : "-"
        }}
      </template>

      <template v-slot:[`item.assignmentEnd`]="{ item }">
        {{ item.dateEnd ? dayjs(item.dateEnd).format("DD/MM/YYYY") : "-" }}
      </template>

      <template v-slot:[`item.title`]="{ item }">
        {{
          item.title.length > 70
            ? item.title.substring(0, 60) + "..."
            : item.title
        }}
      </template>

      <template v-slot:[`item.importance`]="{ item }">
        <span
          style="text-transform: capitalize"
          class="primary px-2 pa-1 rounded-pill white--text"
        >
          {{ item.importance }}
        </span>
      </template>

      <template v-slot:[`item.send`]="{ item }">
        <div class="d-flex white--text">
          <div class="bullet filgreen disabled mr-2">
            {{ item.sentToNum }}
          </div>

          <div class="bullet filgreen">
            {{ item.openedByNum }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.isFinalized`]="{ item }">
        <div class="d-flex align-center justify-center">
          <div
            class="bullet small"
            :class="{
              success: item.isFinalized,
              error: !item.isFinalized,
            }"
          ></div>
        </div>
      </template>

      <template v-slot:[`item.dateCompleted`]="{ item }">
        {{
          item.dateCompleted
            ? dayjs(item.dateCompleted).format("DD/MM/YYYY")
            : "-"
        }}
      </template>

      <template v-slot:[`item.executionTimeMinSec`]="{ item }">
        {{
          item.executionTimeMinSec && item.executionTimeMinSec != "0"
            ? item.executionTimeMinSec
            : "-"
        }}
      </template>

      <template v-slot:[`item.green`]="{ item }">
        <div
          class="bullet large filgreen"
          v-if="
            item.questionnaireTypeId == 'psycho' &&
              item.isFinalized &&
              info.currentLogin.gmCanReadResults
          "
        >
          {{ item.green }}
        </div>

        <div v-else>-</div>
      </template>

      <template v-slot:[`item.yellow`]="{ item }">
        <div
          class="bullet large filyellow"
          v-if="
            item.questionnaireTypeId == 'psycho' &&
              item.isFinalized &&
              info.currentLogin.gmCanReadResults
          "
        >
          {{ item.yellow }}
        </div>

        <div v-else>-</div>
      </template>

      <template v-slot:[`item.red`]="{ item }">
        <div
          class="bullet large filred"
          v-if="
            item.questionnaireTypeId == 'psycho' &&
              item.isFinalized &&
              info.currentLogin.gmCanReadResults
          "
        >
          {{ item.red }}
        </div>
        <div v-else>-</div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="
            item.isFinalized &&
              ((item.questionnaireTypeId == 'psycho' &&
                info.currentLogin.gmCanReadResults) ||
                (item.questionnaireTypeId == 'elearn' &&
                  info.currentLogin.gmCanAccessElearning) ||
                (item.questionnaireTypeId == 'form' &&
                  info.currentLogin.gmCanAccessForms))
          "
          small
          @click="onShowReport(item)"
          width="60px"
          >report</v-btn
        >
      </template>
    </v-data-table>

    <div class="pagination-wrapper py-3 mt-auto">
      <v-pagination
        v-model="page"
        :length="pages"
        @input="tab = null"
        v-if="pages > 0"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ShowPerPage from "@/components/ShowPerPage";

export default {
  props: ["selectedUser"],

  components: {
    ShowPerPage,
  },
  data() {
    return {
      historyLoader: false,
      headers: [
        { text: "Date Assigned ", value: "emailSentOn" },
        { text: "Assignment End", value: "assignmentEnd" },
        { text: "Questionnaire Type", value: "type" },
        { text: "Questionnaire Title", value: "questionnaireName" },
        { text: "Completed", value: "isFinalized" },
        { text: "Completion Date", value: "dateCompleted" },
        { text: "Execution Time", value: "executionTimeMinSec" },
        { text: "red", value: "red" },
        { text: "yellow", value: "yellow" },
        { text: "green", value: "green" },
        { text: "", value: "action" },
      ],
      dialog: { open: false, type: null, item: null },
      timeout: null,
      showPerPageOptions: [],
      history: [],
      pageSize: 10,
      page: 1,
      search: null,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.base.info,
    }),
    checkGmRoleType() {
      if (
        this.info.currentLogin.gmCanAccessElearning ||
        this.info.currentLogin.gmCanAccessForms
      ) {
        return "gmCanSeeReport";
      } else if (this.info.currentLogin.gmCanReadResults) {
        return "gmCanSeeEverything";
      } else {
        return "gmCanSeeNothing";
      }
    },

    searchHistory() {
      return this.search
        ? this.selectedUser.assignedQuestionnaires.filter((questionnaire) =>
            questionnaire.questionnaireName
              .toLowerCase()
              .includes(this.search.toLowerCase())
          )
        : null;
    },
    totalHistory() {
      return this.search
        ? this.searchHistory
        : this.selectedUser.assignedQuestionnaires;
    },
    paginatedHistory() {
      return this.totalHistory.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    pages() {
      return Math.ceil(
        this.selectedUser.assignedQuestionnaires.length / this.pageSize
      );
    },
  },

  created() {
    this.showPerPageOptions = [
      {
        itemsPerPage: 5,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 10,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: 100,
        setPerPage: this.setPerPage,
      },
      {
        itemsPerPage: -1,
        setPerPage: this.setPerPage,
      },
    ];
  },
  methods: {
    ...mapActions(["getSelectedUser"]),
    ...mapMutations(["toggleShowReport", "toggleSnackbar"]),
    setPerPage(options) {
      this.page = options.page;
      this.pageSize = options.itemsPerPage == -1 ? 1000 : options.itemsPerPage;
    },
    onShowReport(item) {
      this.toggleShowReport({
        open: true,
        id: item.assignUserQuesId,
        index: 1,
      });
    },
  },
};
</script>

<style lang="scss">
.bullet {
  &.small {
    height: 12px;
    width: 12px;
  }
}

.mock {
  width: 60px;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
